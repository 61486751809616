<template>
  <!-- 数据看板 -->
  <div class="home" :class="{ home2: !iconFangda }">
    <div class="homeContent">
      <div class="recordSta">
        <div class="recordStaCon">
          <div class="recordStaName">健康码实时数据</div>
          <div class="recordStaNum">
            <div class="stateNum" v-for="(itme, index) in states" :key="index">
              <div class="stateNumTitle">
                {{ itme.title }}
              </div>
              <div class="stateNumCon" :class="{ stateNumCon2: index === 2 }">
                <!-- @click="lvmaFun(topData, index, '绿码')" -->
                <div class="stateNumContent">
                  <div class="stateNumContentC">
                    <i class="iconfont icon-erweima lvma"></i>
                    <div class="stateNumContentText">绿码</div>
                  </div>
                  <div class="stateNumContentNum">
                    {{ itme.content.greenColor }}
                  </div>
                </div>
                <!-- @click="lvmaFun(topData, index, '黄码')" -->
                <div class="stateNumContent">
                  <div class="stateNumContentC">
                    <i class="iconfont icon-erweima huangma"></i>
                    <div class="stateNumContentText">黄码</div>
                  </div>
                  <div class="stateNumContentNum">
                    {{ itme.content.yellowColor }}
                  </div>
                </div>
                <!-- @click="lvmaFun(topData, index, '红码')" -->
                <div class="stateNumContent stateNumContent2">
                  <div class="stateNumContentC">
                    <i class="iconfont icon-erweima hongma"></i>
                    <div class="stateNumContentText">红码</div>
                  </div>
                  <div class="stateNumContentNum">
                    {{ itme.content.redColor }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="recordStaCon2">
          <div class="stateCon">
            <div class="stateConTitle">健康码占比</div>
            <div class="stateConBu">
              <div
                @click="dayNumberFun"
                class="sevenSty"
                :class="{ sevenSty2: dayNumber }"
              >
                今天
              </div>
              <div
                @click="sevenNumberFun"
                class="sevenSty"
                :class="{ sevenSty2: sevenNumber }"
              >
                本周
              </div>
              <div
                @click="thirtyNumberFun"
                class="sevenSty"
                :class="{ sevenSty2: thirtyNumber }"
              >
                本月
              </div>
            </div>
          </div>
          <div class="recordStaNum" id="registerChart"></div>
        </div>
      </div>
      <div class="homeCon">
        <div class="echartHead">
          <div class="echartTitle">通行人次</div>
          <div class="echartTime">
            <div
              @click="dayNumberFun2"
              class="sevenSty"
              :class="{ sevenSty2: dayNumber2 }"
            >
              今天
            </div>
            <div
              @click="sevenNumberFun2"
              class="sevenSty"
              :class="{ sevenSty2: sevenNumber2 }"
            >
              本周
            </div>
            <div
              @click="thirtyNumberFun2"
              class="sevenSty"
              :class="{ sevenSty2: thirtyNumber2 }"
            >
              本月
            </div>
          </div>
        </div>
        <div class="echartCon" id="registerChart2" ref="tapp"></div>
      </div>
    </div>
    <div class="iconFD" @click="iconFun">
      <i v-if="iconFangda" class="iconfont icon-fangda"></i>
      <i v-else class="iconfont icon-suoxiao"></i>
    </div>
  </div>
</template>
<script>
import elementResizeDetectorMaker from "element-resize-detector";
// import dayjs from 'dayjs'
// const space = 30
// let _minDate = ''
export default {
  name: "home",
  components: {},
  data() {
    return {
      // 方法图标 是否显示
      iconFangda: true,
      // 时间快捷键是否选中 1day
      dayNumber: true,
      // 时间快捷键是否选中 7day
      sevenNumber: false,
      // 时间快捷键是否选中 30day
      thirtyNumber: false,
      // 时间快捷键是否选中 1day
      dayNumber2: true,
      // 时间快捷键是否选中 7day
      sevenNumber2: false,
      // 时间快捷键是否选中 30day
      thirtyNumber2: false,
      // 健康码实时数据
      states: [
        {
          title: "今日健康码状态",
          content: {},
        },
        {
          title: "本周健康码状态",
          content: {},
        },
        {
          title: "本月健康码状态",
          content: {},
        },
      ],
      // 顶部统计
      topData: {},
      // 人员总量 数据
      myData2: [],
      // 人员总量 传值
      timeCheckStr: "todayStr",
      setInterval1: null,
    };
  },
  mounted() {
    this.getTop();
    this.getDrawLine2();

    this.$nextTick(() => {
      let setInterval1 = setInterval(this.getTop, 60000);
      sessionStorage.setItem("setInterval1", setInterval1);
    });
    this.$nextTick(() => {
      let setInterval2 = setInterval(this.getDrawLine2, 60000);
      sessionStorage.setItem("setInterval2", setInterval2);
    });
  },
  created() {},
  methods: {
    lvmaFun(value, index, ma) {
      if (index === 0) {
        sessionStorage.setItem("jkmStartTime", value.todayStartTime);
        sessionStorage.setItem("jkmEndTime", value.todayEndTime);
      } else if (index === 1) {
        sessionStorage.setItem("jkmStartTime", value.weekStartTime);
        sessionStorage.setItem("jkmEndTime", value.weekEndTime);
      } else if (index === 2) {
        sessionStorage.setItem("jkmStartTime", value.monthStartTime);
        sessionStorage.setItem("jkmEndTime", value.monthEndTime);
      }
      sessionStorage.setItem("jiankangmaName", ma);
      this.$router.push("/content/record");
    },
    myHW() {
      const _this = this;
      const erd = elementResizeDetectorMaker();
      erd.listenTo(document.getElementById("app"), (element) => {
        _this.$nextTick(() => {
          if (this.iconFangda) {
            let height = element.offsetHeight - 520;
            let width = element.offsetWidth - 300;
            this.$refs.tapp.style.height = height + "px";
            this.$refs.tapp.style.width = width + "px";
            this.drawLine2(height, width);
          } else {
            let height = element.offsetHeight - 380;
            let width = element.offsetWidth - 60;
            this.$refs.tapp.style.height = height + "px";
            this.$refs.tapp.style.width = width + "px";
            this.drawLine2(height, width);
          }
        });
      });
    },
    // 点击 放大缩小
    iconFun() {
      this.iconFangda = !this.iconFangda;
      this.myHW();
      this.drawLine();
    },
    // 顶部 统计
    getTop() {
      this.$instance
        .get("/v1/visit-log/kanban", {
          params: {},
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.topData = res.data.data;
            this.states[0].content = this.topData.todayStat;
            this.states[1].content = this.topData.weekStat;
            this.states[2].content = this.topData.monthStat;
            this.drawLine();
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // 点击 时间快捷键 1day
    dayNumberFun() {
      this.sevenNumber = false;
      this.thirtyNumber = false;
      this.dayNumber = true;
      this.drawLine();
    },
    // 点击 时间快捷键 7day
    sevenNumberFun() {
      this.sevenNumber = true;
      this.thirtyNumber = false;
      this.dayNumber = false;
      this.drawLine();
    },
    // 点击 时间快捷键 30day
    thirtyNumberFun() {
      this.sevenNumber = false;
      this.thirtyNumber = true;
      this.dayNumber = false;
      this.drawLine();
    },
    // 饼状图 注册数据
    drawLine() {
      let echarts = require("echarts");
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("registerChart"));
      window.onresize = function () {
        myChart.resize();
      };
      // 重新绘制
      myChart.clear();
      // 选中时间的数据
      var valueColor = {};
      if (this.dayNumber === true) {
        // 今天
        valueColor = this.topData.todayStat;
      } else if (this.sevenNumber === true) {
        // 本周
        valueColor = this.topData.weekStat;
      } else if (this.thirtyNumber === true) {
        // 本月
        valueColor = this.topData.monthStat;
      }
      var data = [
        {
          value: valueColor.redColor,
          name: "红码",
        },
        { value: valueColor.greenColor, name: "绿码" },
        { value: valueColor.yellowColor, name: "黄码" },
      ];

      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        title: {
          text: valueColor.kanBanAllColor,
          subtext: "今日总量",
          textAlign: "center",
          itemGap: 4,
          left: "25%",
          top: "38%",
          textStyle: {
            fontSize: 14,
            align: "center",
          },
          subtextStyle: {
            fontSize: 10,
            align: "center",
          },
        },
        legend: {
          orient: "vertical",
          top: "30",
          right: "0",
          data: [
            {
              name: "红码",
              icon: "circle",
            },
            {
              name: "绿码",
              icon: "circle",
            },
            {
              name: "黄码",
              icon: "circle",
            },
          ],
          formatter: function (name) {
            var total = 0;
            var target;
            for (var i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                target = data[i].value;
              }
            }
            var arr = [];
            if (target !== 0) {
              arr = [
                "{a|" +
                  name +
                  " :}{b|" +
                  ((target / total) * 100).toFixed(2) +
                  "%}",
              ];
            } else {
              arr = ["{a|" + name + " :}{b|" + "0" + "%}"];
            }
            return arr.join("\n");
          },
          textStyle: {
            rich: {
              a: {
                fontSize: 12,
                padding: [0, 0, 0, 4],
              },
              b: {
                fontSize: 12,
                padding: [0, 0, 0, 4],
              },
            },
          },
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["50%", "70%"],
            center: ["26%", "50%"],
            data: [
              {
                value: valueColor.redColor,
                name: "红码",
                itemStyle: { color: "#f07070" },
              },
              {
                value: valueColor.greenColor,
                name: "绿码",
                itemStyle: { color: "#69d665" },
              },
              {
                value: valueColor.yellowColor,
                name: "黄码",
                itemStyle: { color: "#ffda60" },
              },
            ],
            avoidLabelOverlap: false,
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
          },
        ],
      });
    },
    // 点击 时间快捷键 1day
    dayNumberFun2() {
      this.sevenNumber2 = false;
      this.thirtyNumber2 = false;
      this.dayNumber2 = true;
      this.timeCheckStr = "todayStr";
      this.getDrawLine2();
    },
    // 点击 时间快捷键 7day
    sevenNumberFun2() {
      this.sevenNumber2 = true;
      this.thirtyNumber2 = false;
      this.dayNumber2 = false;
      this.timeCheckStr = "weekStr";
      this.getDrawLine2();
    },
    // 点击 时间快捷键 30day
    thirtyNumberFun2() {
      this.sevenNumber2 = false;
      this.thirtyNumber2 = true;
      this.dayNumber2 = false;
      this.timeCheckStr = "monthStr";
      this.getDrawLine2();
    },
    // 人员总量 接口
    getDrawLine2() {
      this.$instance
        .get("/v1/visit-log/kanban-list", {
          params: {
            timeCheckStr: this.timeCheckStr,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.myData2 = res.data.data;
            this.drawLine2();
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // 折线图 认证数趋势
    drawLine2(height, width) {
      let echarts2 = require("echarts");
      // 基于准备好的dom，初始化echarts实例
      let myChart2 = echarts2.init(document.getElementById("registerChart2"));
      // 重新绘制
      myChart2.clear();
      myChart2.resize({ height: height, width: width });
      // 绘制图表
      myChart2.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        color: ["#409eff"],
        grid: {
          left: "3%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.myData2.timeStr,
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            max: function (value) {
              const formatInt = (num, prec = 2, ceil = true) => {
                const len = String(num).length;
                if (len < prec) {
                  return num;
                }

                const mult = Math.pow(10, prec);
                return ceil
                  ? Math.ceil(num / mult) * mult
                  : Math.floor(num / mult) * mult;
              };
              return formatInt(value.max, 2, true);
            },
          },
        ],
        series: [
          {
            smooth: true,
            type: "line",
            data: this.myData2.countInt,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(60, 177, 255,0.2)", // 0% 处的颜色
                  },
                  {
                    offset: 0.9,
                    color: "rgba(0,0,0,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  position: relative;
  .homeContent {
    height: 100%;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
    .recordSta {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .recordStaCon,
      .recordStaCon2 {
        width: 66%;
        height: 240px;
        display: flex;
        flex-direction: column;
        margin: 0 0 20px 0;
        border-radius: 4px;
        background-color: #fff;
        padding: 20px;
        box-sizing: border-box;
        .recordStaName {
          margin-bottom: 30px;
        }
        .recordStaNum {
          height: 180px;
          display: flex;
          .stateNum {
            width: 33%;
            .stateNumTitle {
              color: #999;
              font-size: 12px;
              margin-bottom: 10px;
              padding-left: 30px;
            }
            .stateNumCon {
              padding: 0 30px;
              border-right: 1px #f0f0f0 solid;
              .stateNumContent {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // cursor: pointer;
                .stateNumContentC {
                  display: flex;
                  align-items: center;
                  margin: 10px 0;
                  .iconfont {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 2px;
                    margin-right: 10px;
                    font-size: 12px;
                  }
                  .lvma {
                    color: #7eec7a;
                    background-color: rgba(148, 236, 156, 0.2);
                  }
                  .huangma {
                    color: #e7c860;
                    background-color: rgba(253, 210, 67, 0.2);
                  }
                  .hongma {
                    color: #f07070;
                    background-color: rgba(238, 169, 169, 0.2);
                  }
                  .stateNumContentText {
                    color: #999;
                    font-size: 12px;
                  }
                }
                .stateNumContentNum {
                  font-size: 16px;
                  font-weight: bold;
                }
              }
            }
            .stateNumCon2 {
              border-right: none;
            }
          }
        }
      }
      .recordStaCon {
        margin-right: 20px;
      }
      .recordStaCon2 {
        width: 34%;
        // 健康码占比顶部
        .stateCon {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .stateConBu {
            display: flex;
            .sevenSty {
              width: 44px;
              line-height: 24px;
              border-radius: 2px;
              text-align: center;
              background-color: #f0f0f0;
              font-size: 12px;
              cursor: pointer;
            }
            .sevenSty2 {
              color: #fff;
              background-color: #409eff;
            }
          }
        }
      }
    }
    .homeCon {
      width: 100%;
      height: calc(100% - 260px);
      background-color: #fff;
      border-radius: 4px;
      .echartHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px #e7e7e7 solid;
        .echartTitle {
          font-size: 16px;
        }
        .echartTime {
          display: flex;
          .sevenSty {
            width: 44px;
            line-height: 24px;
            border-radius: 2px;
            text-align: center;
            background-color: #f0f0f0;
            font-size: 12px;
            cursor: pointer;
          }
          .sevenSty2 {
            color: #fff;
            background-color: #409eff;
          }
        }
      }
      .echartCon {
        width: 100%;
        height: calc(100% - 80px);
      }
    }
  }
  .iconFD {
    width: 46px;
    height: 46px;
    position: absolute;
    bottom: 10px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    box-shadow: 0px 0px 10px #000;
    color: #fff;
    cursor: pointer;
    .iconfont {
      font-size: 24px;
    }
  }
}
.home2 {
  height: 100vh !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #f0f0f0;
}
</style>
